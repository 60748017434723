/**
 * Component to display the preview of the customized Mizuki
 */

import React, { useState, useEffect } from 'react'
import LoadingOverlay from 'react-loading-overlay'
import { useAccount } from 'wagmi'
import NFTSaveModal from './NFTSaveModal'
import { toast } from 'react-toastify'

// Loads them through the local API, even though they're served from S3, to ensure canvas is "clean" for save
const assetBaseURI = "/assets/"

/**
 * Gets the proper "back" hair name for the given hair name
 * 
 * @param name The hair name
 * @returns The back hair name
 */
const hairBack = (name: String) => {
    let secondaryName = "No Behind"
    if (name.includes("Bangs") || name.includes("Long")) {
        secondaryName = name + " Behind"
    } else if(name.includes("Chopped")) {
        secondaryName = "Chopped Behind"
    }

    return secondaryName
}

type MizukiPreviewProps = {
    selectedOptions: any[],
    traitHash: string,
    importOptions(options: any[]): void
}

const MizukiPreview = (props: MizukiPreviewProps) => {
    const { selectedOptions, traitHash } = props
    const [minting, setMinting] = useState(false)
    const [loading, setLoading] = useState(true)

    const { address, isConnecting, isDisconnected } = useAccount()

    // Redraw the image when the trait hash changes
    useEffect(() => {
        rerenderImage()
    }, [props.traitHash])

    useEffect(() => {
        rerenderImage()
    }, [])

    /**
     * Copy the contents of the editor to the clipboard as a base64 code
     */
    const copy = () => {
        navigator.clipboard.writeText(Buffer.from(JSON.stringify(selectedOptions), 'utf8').toString('base64'))

        toast("base[d]64 code copied to clipboard!", {
            position: "bottom-center",
            theme: "dark"
        })
    }

    /**
     * Paste a base64 code from the clipboard into the editor
     */
    const paste = () => {
        navigator.clipboard.readText().then((text) => {
            try {
                const decoded = Buffer.from(text.trim(), 'base64').toString('utf8')

                if(!decoded.indexOf("Eyebrows") || !decoded.indexOf("Body") || !decoded.indexOf("Eyes") || !decoded.indexOf("Clothes") || !decoded.indexOf("Face Decoration") || !decoded.indexOf("Glasses") || !decoded.indexOf("Hair") || !decoded.indexOf("Mouth") || !decoded.indexOf("Hat") || !decoded.indexOf("Earrings") || !decoded.indexOf("Color Grading")) {
                    throw new Error("Invalid base[d]64 code!")
                }

                const options = JSON.parse(decoded)
                props.importOptions(options)

                toast("base[d]64 code imported!", {
                    position: "bottom-center",
                    theme: "dark"
                })
            } catch (e) {
                toast("Invalid base[d]64 code!", {
                    position: "bottom-center",
                    theme: "dark"
                })
            }
        })
    }

    /**
     * Save the contents of the canvas as a PNG file
     */
    const download = () => {
        const canvas: any = document.getElementById("canvas")
        if(!canvas)
            return

        var link = document.createElement('a')
        link.download = 'mizuki.png'
        link.href = canvas.toDataURL()
        link.click()
    }

    /**
     * Redraw the image based on new parameters
     */
    const rerenderImage = () => {
        setLoading(true)

        const canvas: any = document.getElementById("canvas")
        if(!canvas)
            return

        const ctx = canvas.getContext("2d")
        ctx.imageSmoothingQuality = "high"

        let loadedCount = 0

        // Pluck our options out of the array they're in
        const background: any = selectedOptions.find((option) => option.traitName === "Background") ?? {}
        const body: any = selectedOptions.find((option) => option.traitName === "Body") ?? {}
        const eyes: any = selectedOptions.find((option) => option.traitName === "Eyes") ?? {}
        const eyebrows: any = selectedOptions.find((option) => option.traitName === "Eyebrows") ?? {}
        const clothes: any = selectedOptions.find((option) => option.traitName === "Clothes") ?? {}
        const faceDecoration: any = selectedOptions.find((option) => option.traitName === "Face Decoration") ?? {}
        const glasses: any = selectedOptions.find((option) => option.traitName === "Glasses") ?? {}
        const hair: any = selectedOptions.find((option) => option.traitName === "Hair") ?? {}
        const mouth: any = selectedOptions.find((option) => option.traitName === "Mouth") ?? {}
        const hat: any = selectedOptions.find((option) => option.traitName === "Hat") ?? {}
        const earrings: any = selectedOptions.find((option) => option.traitName === "Earrings") ?? {}
        const colorGrading: any = selectedOptions.find((option) => option.traitName === "Color Grading") ?? {}

        const layers: any[] = []

        layers[0] = new Image()
        layers[0] .src = assetBaseURI + background.valueName + ".png"
        layers[0] .onload = () => {
            loadedCount++
            if(loadedCount === 13)
                draw()
        }

        layers[1] = new Image()
        layers[1].src = assetBaseURI + hairBack(hair.valueName) + ".png"
        layers[1].onload = () => {
            loadedCount++
            if(loadedCount === 13)
                draw()
        }

        layers[2] = new Image()
        layers[2].src = assetBaseURI + body.valueName + ".png"
        layers[2].onload = () => {
            loadedCount++
            if(loadedCount === 13)
                draw()
        }

        layers[3] = new Image()
        layers[3].src = assetBaseURI + eyes.valueName + ".png"
        layers[3].onload = () => {
            loadedCount++
            if(loadedCount === 13)
                draw()
        }

        layers[4] = new Image()
        layers[4].src = assetBaseURI + eyebrows.valueName + ".png"
        layers[4].onload = () => {
            loadedCount++
            if(loadedCount === 13)
                draw()
        }

        layers[5] = new Image()
        layers[5].src = assetBaseURI + clothes.valueName + ".png"
        layers[5].onload = () => {
            loadedCount++
            if(loadedCount === 13)
                draw()
        }

        layers[6] = new Image()
        layers[6].src = assetBaseURI + faceDecoration.valueName + ".png"
        layers[6].onload = () => {
            loadedCount++
            if(loadedCount === 13)
                draw()
        }

        layers[7] = new Image()
        layers[7].src = assetBaseURI + glasses.valueName + ".png"
        layers[7].onload = () => {
            loadedCount++
            if(loadedCount === 13)
                draw()
        }

        layers[8] = new Image()
        layers[8].src = assetBaseURI + hair.valueName + ".png"
        layers[8].onload = () => {
            loadedCount++
            if(loadedCount === 13)
                draw()
        }

        layers[9] = new Image()
        layers[9].src = assetBaseURI + mouth.valueName + ".png"
        layers[9].onload = () => {
            loadedCount++
            if(loadedCount === 13)
                draw()
        }
        
        layers[10] = new Image()
        layers[10].src = assetBaseURI + hat.valueName + ".png"
        layers[10].onload = () => {
            loadedCount++
            if(loadedCount === 13)
                draw()
        }
        
        layers[11] = new Image()
        layers[11].src = assetBaseURI + earrings.valueName + ".png"
        layers[11].onload = () => {
            loadedCount++
            if(loadedCount === 13)
                draw()
        }

        layers[12]= new Image()
        layers[12].src = assetBaseURI + colorGrading.valueName + ".png"
        layers[12].onload = () => {
            loadedCount++
            if(loadedCount === 13)
                draw()
        }

        // Draws all the layers once the PNGs are pre-loaded
        const draw = () => {
            for(const layer of layers) {
                ctx.drawImage(layer, 0, 0, canvas.width, canvas.height)
            }
            setLoading(false)
        }
    }

    return (
        <div className="mizuki-preview  w-[100%] lg:w-[50%] text-black">
            <NFTSaveModal options={selectedOptions} isOpen={minting} onClose={() => setMinting(false)} />

            <LoadingOverlay
                active={loading}
                text="Making Mizuki..."
                spinner
                >
                <canvas id="canvas" width={500} height={500}></canvas>
            </LoadingOverlay>
            

            <div className="image-buttons">
                <button type="button" onClick={download} className="bg-blue-800 hover:bg-blue-600 rounded-md text-white m-2">Save .PNG</button>
                {address && <button type="button" onClick={() => setMinting(true)} className="bg-blue-800 hover:bg-blue-600 rounded-md text-white m-2">Save to NFT</button>}
                <button type="button" onClick={copy} className="bg-blue-800 hover:bg-blue-600 rounded-md text-white m-2">Copy</button>
                <button type="button" onClick={paste} className="bg-blue-800 hover:bg-blue-600 rounded-md text-white m-2">Paste</button>
            </div>
        </div>
    )
}

export default MizukiPreview