/**
 * Component for selecting which trait to edit
 */

import React from 'react'
import ITrait from '../../interfaces/ITrait'

type TraitBarProps = {
    trait: ITrait,
    onPrev: () => void,
    onNext: () => void
}

const TraitBar = (props: TraitBarProps) => {
    const { trait, onPrev, onNext } = props

    return(
        <div className="menubar-container inline-block lg:flex flex-row">
            <div className="emptybar inline-block w-[100%] lg:w-[50%]">&nbsp;</div>
            <div className="traitbar inline-block text-5xl w-[100%] lg:w-[50%]">
                <button type="button" onClick={onPrev} className="button-prev">&lt;</button>
                {trait.name}
                <button type="button" onClick={onNext} className="button-next">&gt;</button>
            </div>
        </div>
    )
}

export default TraitBar