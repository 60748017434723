/**
 * Component to enable the user to select a trait option for the current trait
 */

import React from 'react'
import { BUTTON_IMG_FOLDER } from '../../const'
import ITrait from '../../interfaces/ITrait'

type TraitSelectorProps = {
    trait: ITrait
    onUpdateOption: (traitName: string, valueName: string) => void
    selectedOptions: any[],
    traitHash: string
}

/**
 * Transforms an asset filename to a button filename
 */
const getButtonFileName = (fileName: string) => {
    return fileName.replace("assets/", "").replace(".png", ".webp").replace(" ", "_").replace(" ", "_").toLowerCase()
}

const TraitSelector = (props: TraitSelectorProps) => {
    const { trait, onUpdateOption, selectedOptions } = props

    return (
        <div className="trait-selector w-[100%] h-[100%] text-black">
            {trait && trait.options && trait.options.map((option) => {
                const isSelected = selectedOptions.some((selectedOption) => {
                    return selectedOption.traitName === trait.name && selectedOption.valueName === option.name
                })

                const className = isSelected ? 'trait-option selected' : 'trait-option'

                return (
                    <div className={className} onClick={(e) => onUpdateOption(trait.name, option.name)} key={option.name}>
                        <div style={{ backgroundImage:`url(${BUTTON_IMG_FOLDER}${encodeURI(getButtonFileName(option.fileName))})` }} className="absolute inset-0 bg-cover bg-center z-0">
                            <div className="opacity-0 hover:opacity-100 hover:bg-slate-600/50 duration-300 absolute inset-0 z-10 flex justify-center items-center text-lg text-white font-semibold">{option.name}</div>
                        </div>
                    </div>
                )
            })}
        </div>
    )
}

export default TraitSelector