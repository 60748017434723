/**
 * React component to display the whitepaper in a faux WordPad window
 */

import React from 'react'

type WhitepaperViewProps = {
    onClose: () => void
}

const WordSubheader = (props: any) => {
    return <h2 className="text-lg mt-6 mb-3 font-bold">{props.children}</h2>
}

const WordParagraph = (props: any) => {
    return <p>{props.children}</p>
}

const WhitepaperView = (props: WhitepaperViewProps) => {
    const { onClose } = props
    
    return(
        <div>
            <div className="wordfile-container">
                <div className="wordfile-header">
                    WHITEPAPER FINAL.DOC - WERDPAD

                    <button className="windows-button float-right m-0 p-0 text-sm text-normal w-[24px] h-[24px] text-black" style={{backgroundColor:"#C5C5C5"}} onClick={onClose}>X</button>
                </div>
                <div className="whitepaper">
                    <h1 className="mb-6">Mizuki Whitepaper</h1>

                    <WordSubheader>Official Links</WordSubheader>

                    <ul className='list-disc ml-4 mb-6'>
                        <li>
                            Azuki Milady - 
                            &nbsp;
                            <a className="text-blue-600 visited:text-purple-700 underline" target="_new" href="https://etherscan.io/address/0x4a519d24A9d03E51e6DA5732B16420B68943bC32">Etherscan</a> |
                            &nbsp;
                            <a className="text-blue-600 visited:text-purple-700 underline" target="_new" href="https://opensea.io/collection/azuki-milady">OpenSea</a>
                        </li>

                        <li>
                            Mizuki Maker - 
                            &nbsp;
                            <a className="text-blue-600 visited:text-purple-700 underline" target="_new" href="https://etherscan.io/address/0x77c4421636d5C2CeCD53bEda1b0c4aAb3Ffb9918">Etherscan</a> | 
                            &nbsp;
                            <a className="text-blue-600 visited:text-purple-700 underline" target="_new" href="https://opensea.io/collection/mizuki-maker">OpenSea</a>
                        </li>
                    </ul>

                    <div className="w-[100%]">
                        <WordSubheader>Overview</WordSubheader>

                        <WordParagraph>
                            Though initially intended to be a basic derivative crossover between the two esteemed collections Azuki and Milady Maker, the creators of what came to be known as Mizuki learned of how sentimental people were about the art. We too shared these sentiments, and thus the need for something more than a basic derivative project was born.
                        </WordParagraph>

                        <WordParagraph>
                            Thus mizukiOS was born. Given the overwhelming demand for custom art using the Mizuki assets, we placed at its center a character creator. This tool requires spending nothing to use. Anyone can download a regular image of their creations. But we wanted to reward holders of Azuki Milady with a way to mint their designs.
                        </WordParagraph>

                        <WordSubheader>Azuki Milady vs. Mizuki Maker</WordSubheader>

                        <WordParagraph>
                            The need for two distinct NFT collections to encompass the ambitiousness of our project quickly became apparent. We had the original collection with its randomized traits but needed a second one for minting customized designs as well. This led to the creation of the MIZUKI MAKER collection of NFTs.
                        </WordParagraph>

                        <WordParagraph>
                            We did not want to charge existing holders additional money for custom NFTs, thus the idea of an innovative form of staking was conceived: deposit an AZUKI MILADY and get a MIZUKI MAKER. This process is two way – at any time, an original Azuki Milady can be retrieved by burning a Mizuki Maker.
                        </WordParagraph>

                        <ul className='list-disc mt-4 ml-4 mb-6'>
                            <li>Azuki Milady - randomized, immutable, tradable</li>
                            <li>Mizuki Maker - customizable, mutable, soulbound</li>
                        </ul>

                        <WordSubheader>Creating Custom NFTs</WordSubheader>

                        <WordParagraph>
                            The process of making a custom NFT is one that might feel slightly esoteric to those outside of crypto, given it requires numerous steps. However, with an understanding of how the underlying tokens and contracts work, it is quite simple.
                        </WordParagraph>

                        <ol className='list-decimal mt-4 ml-4 mb-6'>
                            <li>Acquire at least one AZUKI MILADY through minting or secondary markets.</li>
                            <li>Give the MIZUKI MAKER contract approval to access your AZUKI MILADY tokens</li>
                            <li>Deposit an AZUKI MILADY to get a soulbound MIZUKI MAKER</li>
                            <li>Design your perfect Mizuki through the Maker.exe app in mizukiOS</li>
                            <li>Hit “Save to NFT” to choose a MIZUKI MAKER to accept the gift of your design</li>
                            <li>Our backend makes a high-quality PNG of your design and pins it to IPFS</li>
                            <li>Sign a transaction to update the metadata &mdash; only paying gas.</li>
                        </ol>

                        <WordSubheader>On Right Clickoors</WordSubheader>

                        <WordParagraph>
                            Though we offer official NFTs, we recognize that much of Milady culture involves right-clicking, and we want to make it easy for people to just use designs as-is, without any NFTs being involved. Thus, we have included a “Save as .PNG” in the Maker.exe editor.
This does not even rely on a server-side call! Each layer is drawn on a JavaScript canvas component, whose contents can be directly saved as an image.
                        </WordParagraph>

                        <WordSubheader>Withdrawing Azuki Milady</WordSubheader>

                        <WordParagraph>
                            Given the beauty and spirituality present in each Milady, we did not want to make the redemption process to function as a one-way process &mdash; instead choosing a form of staking. Our system is also flexible in that each custom Mizuki Maker is not tightly coupled to a staked Azuki Milady.
                        </WordParagraph>
                        <WordParagraph>
                            Any Mizuki Maker may be burned to unstake an original Azuki Milady. This means that if you like your original Azuki Milady design but are eager to make a custom NFT without &mdash; at this point in time &mdash; purchasing an Azuki Milady you are willing to keep staked indefinitely, you may buy it later to unstake a previously staked NFT in the following process:
                        </WordParagraph>

                        <ol className='list-decimal mt-4 ml-4 mb-6'>
                            <li>The AZUKI MILADY you like is staked to get a MIZUKI MAKER</li>
                            <li>This MIZUKI MAKER used to create a custom NFT that you like</li>
                            <li>Another AZUKI MILADY is purchased and staked to get second MIZUKI MAKER</li>
                            <li>Second MIZUKI MAKER is burned to redeem the original AZUKI MILADY</li>
                        </ol>

                        <WordParagraph>
                            We believe that this is the fairest and most flexible way of handling official customs. Even if holders can recreate designs with our copy & paste features, it is better if people have ways to keep their soulbound NFT.
                        </WordParagraph>

                        <WordSubheader>Trait Catalog</WordSubheader>

                        <WordParagraph>
                            The original Azuki Milady collection launched with a wealth of trait options that reflected a lot of the diversity of drip found in the original Milady Maker collection. However, the fusion of new memes and nostalgic soul at the core of Milady is constantly producing new possibilities that can be reflected in our character creator.
                        </WordParagraph>

                        <WordParagraph>
                            It is clearly critical to work with the community to ensure that additions to this catalog of culture are desired and valued, rather than filling the creator with endless options for the sake of options. Azuki Milady should not experience a dilution in the quality of the art that won it a place in so many people's hearts with the original pfp launch.
                        </WordParagraph>

                        <WordSubheader>mizukiOS</WordSubheader>

                        <WordParagraph>
                            As Mizuki grows in scope, it became important for us to be able to carefully craft the experiences associated with the project we want, evoking the same nostalgic early 00s style of Milady Maker.
                        </WordParagraph>

                        <WordParagraph>
                            This also allows a platform for further Azuki Milady-based experiences if the demand and quality of ideas is there. While this works for the basic use cases we have at launch at providing a place to mint NFTs and create custom characters, it offers a lot of possibility for more.
                        </WordParagraph>

                        <ul className='list-disc mt-4 ml-4 mb-6'>
                            <li>Minter.exe - UX-friendly way to handle minting, withdrawal, and deposits of NFTs</li>
                            <li>Maker.exe - The character creator tool, which allows both saving to PNGs or to a Mizuki Maker NFT</li>
                        </ul>

                        <WordSubheader>Future Plans</WordSubheader>

                        <WordParagraph>
                            This project started as a simple crossover derivative profile picture idea and we are overjoyed at how much it means to so many people. We are excited to see what the future holds for Mizuki and the community that has grown around it. We want that community to help define what the project looks like going forward. We are merely the builders. All of us collectively together are Mizuki, united through network spirtuality.
                        </WordParagraph>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default WhitepaperView