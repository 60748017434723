/**
 * Top-level component for the mizukiOS web app.
 */

import React, { useState, useEffect } from 'react'
import axios from 'axios'
import md5 from 'md5'
import MizukiMaker from './MizukiMaker'
import MizukiMinter from './MizukiMinter'
import WhitepaperView from './WhitepaperView'
import {
    getDefaultWallets,
    RainbowKitProvider,
    ConnectButton
  } from '@rainbow-me/rainbowkit'
import { configureChains, createClient, WagmiConfig } from 'wagmi'
import { mainnet, goerli } from 'wagmi/chains'
import { alchemyProvider } from 'wagmi/providers/alchemy'
import IMizukiTraitOption from '../interfaces/IMizukiTraitOption'
import { ToastContainer } from 'react-toastify'
import mintIcon from '../img/apps/mint.png'
import makerIcon from '../img/apps/maker.png'
import wordIcon from '../img/apps/word.png'
import { toast } from 'react-toastify'

const { chains, provider } = configureChains(
    [mainnet, /*goerli*/],
    [
        alchemyProvider({ apiKey: "1iWDR56yjfaRLVTIJf3kLoZVhCLsrwvb" })
    ]
)
  
const { connectors } = getDefaultWallets({
    appName: 'Azuki Milady',
    chains
})
  
const wagmiClient = createClient({
    autoConnect: true,
    connectors,
    provider
})

// Traits that the editor loads with
const defaultOptions: IMizukiTraitOption[] = [
    {
        traitName: 'Background',
        valueName: 'Peace'
    },
    {
        traitName: 'Body',
        valueName: 'Pink'
    },
    {
        traitName: 'Clothes',
        valueName: 'Watermelon Jacket'
    },
    {
        traitName: 'Color Grading',
        valueName: 'Yellow'
    },
    {
        traitName: 'Earrings',
        valueName: 'Double Gold'
    },
    {
        traitName: 'Eyebrows',
        valueName: 'Normal',
    },
    {
        traitName: 'Eyes',
        valueName: 'Green Open'
    },
    {
        traitName: 'Face Decoration',
        valueName: 'No Face Decoration'
    },
    {
        traitName: 'Glasses',
        valueName: 'Sunglasses'
    },
    {
        traitName: 'Hair',
        valueName: 'Blue Tuft'
    },
    {
        traitName: 'Hat',
        valueName: 'Sailor Hat'
    },
    {
        traitName: 'Mouth',
        valueName: 'Smirk'
    }
]

/**
 * Gets a standardized hash to represent the trait information
 * 
 * @param mizuki The mizuki data to hash
 * @returns A standardized hash for the trait information
 */
function getTraitHash(mizuki: any) {
    const traitHashes = mizuki.options.sort((a, b) => {
        return a.traitName.localeCompare(b.traitName)
    }).map(option => {
        return `${option.traitName}-${option.valueName}`
    })

    // This isn't a secure hash, but it's fast and good enough for our purposes of indexing
    return md5(traitHashes.join('-'))
}

const WebApp = () => {
    const [app, setApp] = useState('minter')
    const [traits, setTraits] = useState([])
    const [currentTraitIndex, setCurrentTraitIndex] = useState(0)
    const [options, setOptions] = useState<IMizukiTraitOption[]>(defaultOptions)
    const [traitHash, setTraitHash] = useState(getTraitHash({options: defaultOptions}))

    useEffect(() => {
        axios.get('https://azukimiladymaker.net/api/traits').then((res) => {
            setTraits(res.data)
        })
    }, [])

    /**
     * Changes the displayed trait to the next one in the list
     */
    const nextTrait = () => {
        if (currentTraitIndex < traits.length - 1) {
            setCurrentTraitIndex(currentTraitIndex + 1)
        } else {
            setCurrentTraitIndex(0)
        }
    }

    /**
     * Changes the displayed trait to the previous one in the list
     */
    const prevTrait = () => {
        if (currentTraitIndex > 0) {
            setCurrentTraitIndex(currentTraitIndex - 1)
        } else {
            setCurrentTraitIndex(traits.length - 1)
        }
    }

    /**
     * Imports options pasted in as a base64 code
     * 
     * @param options The options to import
     */
    const importOptions = (options: IMizukiTraitOption[]) => {
        setOptions(options)
        setTraitHash(getTraitHash({
            options
        }))
    }

    /**
     * Updates a single trait's option
     * 
     * @param traitName The trait
     * @param valueName The new value of the trait
     */
    const updateOption = (traitName: string, valueName: string) => {
        let matched = false
        options.map((option) => {
            if (option.traitName === traitName) {
                option.valueName = valueName
                matched = true
            }
        })

        if (!matched) {
            options.push({
                traitName,
                valueName
            })
        }

        setTraitHash(getTraitHash({
            options
        }))

        setOptions(options)

        if(traitName === "Hat" && valueName === "Strawberry") {
            updateOption("Hair", "No Hair")
            updateOption("Earrings", "No Earrings")

            toast("Switching to \"No Hair\" & \"No Earrings\" traits to match Strawberry hat.", {
                position: "bottom-center",
                theme: "dark"
            })
        }
    }

    return (
        <WagmiConfig client={wagmiClient}>
            <RainbowKitProvider chains={chains}>
                <div>
                    <div className="float-right m-5">
                        <ConnectButton />
                    </div>

                    <div className="clear-both"></div>

                    <div className="flex flex-row lg:flex-col lg:float-right m-5 mt-20 cursor-pointer">
                        <div className="m-3" onClick={() => setApp("minter")}>
                            <img src={mintIcon} className="h-[128px] m-auto" alt="Mint Mizuki" />
                            <h3 className="filesystem-label">Mint.exe</h3>
                        </div>

                        <div className="m-3" onClick={() => setApp("maker")}>
                            <img src={makerIcon} className="h-[128px] m-auto" alt="Mizuki Maker" />
                            <h3 className="filesystem-label">Maker.exe</h3>
                        </div>

                        <div className="m-3" onClick={() => setApp("whitepaper")}>
                            <img src={wordIcon} className="h-[128px] m-auto" alt="Whitepaper" />
                            <h3 className="filesystem-label">Whitepaper FINAL.doc</h3>
                        </div>
                    </div>

                    {app === "minter" && <MizukiMinter onClose={() => setApp("none")} />}

                    {app === "maker" && <MizukiMaker
                        traits={traits}
                        options={options}
                        traitHash={traitHash}
                        nextTrait={nextTrait}
                        prevTrait={prevTrait}
                        updateOption={updateOption}
                        importOptions={importOptions}
                        currentTraitIndex={currentTraitIndex}
                        onClose={() => setApp("none")}
                        />}

                    {app === "whitepaper" && <WhitepaperView onClose={() => setApp("none")} />}

                    <ToastContainer />
                </div>
            </RainbowKitProvider>
        </WagmiConfig>
    )
}

export default WebApp