import React, { useState } from 'react'
import MizukiSelector from './MizukiSelector'
import azukiMiladyABI from '../../abi/azukiMilady'
import mizukiMakerABI from '../../abi/mizukiMaker'
import { useContractRead, useContractWrite, useAccount, useSigner, useContract } from 'wagmi'
import { toast } from 'react-toastify'

import { MAINNET_MAKER_CONTRACT, MAINNET_MIZUKI_CONTRACT } from '../../const'

const MizukiDepositor = () => {
    const [depositableIds, setDepositableIds] = useState<any>([])
    const [idsToDeposit, setIdsToDeposit] = useState<any[]>([])
    const [depositableMizukis, setDepositableMizukis] = useState<any[]>([])
    const [depositCount, setDepositCount] = useState(0)
    const { address, isConnecting, isDisconnected } = useAccount()

    const { data, isError, isLoading, refetch: originalTokensRefetch } = useContractRead({
        address: MAINNET_MAKER_CONTRACT,
        abi: mizukiMakerABI,
        functionName: 'originalTokensOfOwner',
        args: [address],
    })

    const isApprovedForAll = useContractRead({
        address: MAINNET_MIZUKI_CONTRACT,
        abi: azukiMiladyABI,
        functionName: 'isApprovedForAll',
        args: [address, MAINNET_MAKER_CONTRACT],
    })

    const deposit = useContractWrite({
        mode: 'recklesslyUnprepared',
        address: MAINNET_MAKER_CONTRACT,
        abi: mizukiMakerABI,
        functionName: 'deposit',
        args: [idsToDeposit]
    })

    const setApprovalForAll = useContractWrite({
        mode: 'recklesslyUnprepared',
        address: MAINNET_MIZUKI_CONTRACT,
        abi: azukiMiladyABI,
        functionName: 'setApprovalForAll',
        args: [MAINNET_MAKER_CONTRACT, true]
    })

    const attemptDeposit = (skipApproval = false) => {
        if(depositCount < 1)
            return

        if(!isApprovedForAll.data && !skipApproval) {
            setApprovalForAll.write()
        } else {
            try {
                deposit.write()
            } catch(e) {
                console.log(e)
            }
        }
    }

    const pollApproval = () => {
        isApprovedForAll.refetch().then((res) => {
            console.log(res.data)

            if(res.data) {
                attemptDeposit(true)
            } else {
                setTimeout(() => {
                    pollApproval()
                }, 2500)
            }
        })
    }

    // If we were waiting for a setApprovalForAll(), try depositing again
    if(setApprovalForAll.isSuccess) {
        setApprovalForAll.reset()

        pollApproval()
    }

    if(deposit.isSuccess) {
        deposit.reset()

        originalTokensRefetch();

        const txHash = deposit.data.hash

        toast(<div>Mizuki deposited into contract to create customizable NFTs. <a href={"https://etherscan.io/tx/" + txHash} target="_blank">View on Etherscan!</a></div>, {
            autoClose: 15000,
            position: "bottom-center",
            theme: "dark"
        })
    }

    if(JSON.stringify(data) != JSON.stringify(depositableIds)) {
        setDepositableIds(data)

        const dataEnum = data as number[] ?? []

        const mizukis = dataEnum.map((id: number) => {
            return({
                id,
                imageURL: "https://mizuki.mypinata.cloud/ipfs/QmXHbvbdJFKZRHnwDaSUwNJPbpgh8SdjcJHdsLe6yKryXC/" + id + ".png",
                name: "Mizuki #" + id,
                selected: false
            })
        })

        setDepositableMizukis(mizukis)
    }

    return(
        <div>
            {depositableMizukis && depositableMizukis.length > 0 && (<button type="button" onClick={() => attemptDeposit()} className="bg-blue-800 hover:bg-blue-600 rounded-md text-white m-3">Deposit {depositCount} Mizukis</button>)}

            <MizukiSelector
            mizukis={depositableMizukis}
            toggleMizuki={(m) => {
                let selectedCount = 0

                const updatedMizukis = depositableMizukis.map((m2) => {
                    if(m2.id === m.id) {
                        if(!m2.selected)
                            selectedCount++

                        return {
                            ...m2,
                            selected: !m2.selected
                        }
                    }

                    if(m2.selected)
                        selectedCount++

                    return m2
                })

                setDepositableMizukis(updatedMizukis)
                setDepositCount(selectedCount)
                setIdsToDeposit(updatedMizukis.filter((m) => m.selected).map((m) => m.id))
            }}
            noMizukiMessage={'No Mizukis to Deposit'}
            />
        </div>

        
    )
}

export default MizukiDepositor