/**
 * mizukiOS app that enables the user to create their own Mizuki design
 */

import React from 'react'
import MizukiPreview from './maker/MizukiPreview'
import TraitSelector from './maker/TraitSelector'
import TraitBar from './maker/TraitBar'
import ITrait from '../interfaces/ITrait'
import IMizukiTraitOption from '../interfaces/IMizukiTraitOption'
import WindowMenubar from './WindowMenubar'

type MizukiMakerProps = {
    traits: ITrait[],
    traitHash: string,
    nextTrait: () => void,
    prevTrait: () => void,
    options: IMizukiTraitOption[],
    currentTraitIndex: number,
    updateOption: (traitName: string, valueName: string) => void,
    importOptions(options: IMizukiTraitOption[]): void
    onClose(): void
}

const MizukiMaker = (props: MizukiMakerProps) => {
    const { traits, traitHash, nextTrait, prevTrait, options, currentTraitIndex, updateOption, importOptions, onClose } = props

    return(
        <div>
            <WindowMenubar title="Mizuki Maker" onClose={onClose} />

            <TraitBar onNext={nextTrait} onPrev={prevTrait} trait={traits[currentTraitIndex] ?? []} />

            <div className="editor-container max-h-[1400px] lg:max-h-[600px] flex flex-col lg:flex-row">
                <MizukiPreview selectedOptions={options} traitHash={traitHash} importOptions={importOptions} />

                <div className="trait-selector-container w-[100%] lg:w-[50%] pb-[75px]">
                    <TraitSelector trait={traits[currentTraitIndex] ?? []} onUpdateOption={updateOption} selectedOptions={options} traitHash={traitHash} />
                </div>
            </div>
        </div>
    )
}

export default MizukiMaker