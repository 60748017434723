/**
 * MizukiOS app for minting new Mizuki and depositing/withdrawing for Mizuki Maker
 */

import React, { useState } from 'react'
import { useContractRead, useContractWrite, useAccount } from 'wagmi'
import azukiMiladyABI from '../abi/azukiMilady'
import { ethers } from 'ethers'
import MizukiDepositor from './minter/MizukiDepositor'
import MizukiWithdrawer from './minter/MizukiWithdrawer'
import WindowMenubar from './WindowMenubar'
import { toast } from 'react-toastify'

import { MAINNET_MAKER_CONTRACT, MAINNET_MIZUKI_CONTRACT } from '../const'

/**
 * Counter for the number of original Mizukis minted
 */
const MizukiCounter = () => {
    const { data, isError, isLoading } = useContractRead({
        address: MAINNET_MIZUKI_CONTRACT,
        abi: azukiMiladyABI,
        functionName: 'totalSupply'
    })

    return !data ? <div className="text-4xl mt-4">6666 Supply</div> : (
        <div className="mt-4">
            <div className="text-4xl">{data.toString()} of 6666 Minted</div>
            <div className="w-[70%] bg-gray-200 h-5 m-auto mb-6">
                <div className="bg-blue-400 h-5" style={{width: (parseInt(data.toString())/66.66) + "%"}}></div>
            </div>
        </div>
    )
}

const callMint = async (contractWrite: any) => {
    contractWrite.write()
}

/**
 * Button to mint a single original Mizuki
 */
const MintOneButton = () => {
    const mintOne = useContractWrite({
        mode: 'recklesslyUnprepared',
        address: MAINNET_MIZUKI_CONTRACT,
        abi: azukiMiladyABI,
        functionName: 'mintMizuki',
        overrides: {
            value: ethers.utils.parseEther('.03')
        },
    })

    if(mintOne.isSuccess) {
        mintOne.reset()

        const txHash = mintOne.data.hash

        toast(<div>Now minting a Mizuki. <a href={"https://etherscan.io/tx/" + txHash} target="_blank">View on Etherscan!</a></div>, {
            autoClose: 15000,
            position: "bottom-center",
            theme: "dark"
        })
    }

    return <button onClick={() => callMint(mintOne)} type="button" className="bg-blue-800 hover:bg-blue-600 rounded-md text-white m-3">Mint One for 0.03 ETH</button>
}

/**
 * Button to mint three original Mizukis
 */
const MintThreeButton = () => {
    const mintThree = useContractWrite({
        mode: 'recklesslyUnprepared',
        address: MAINNET_MIZUKI_CONTRACT,
        abi: azukiMiladyABI,
        functionName: 'mint3Mizuki',
        overrides: {
            value: ethers.utils.parseEther('.08')
        },
    })

    if(mintThree.isSuccess) {
        mintThree.reset()

        const txHash = mintThree.data.hash

        toast(<div>Now minting 3 Mizuki. <a href={"https://etherscan.io/tx/" + txHash} target="_blank">View on Etherscan!</a></div>, {
            autoClose: 15000,
            position: "bottom-center",
            theme: "dark"
        })
    }

    return <button onClick={() => callMint(mintThree)} type="button" className="bg-blue-800 hover:bg-blue-600 rounded-md text-white m-3">Mint Three for 0.08 ETH</button>
}

/**
 * Button to mint seven original Mizukis
 */
const MintSevenButton = () => {
    const mintSeven = useContractWrite({
        mode: 'recklesslyUnprepared',
        address: MAINNET_MIZUKI_CONTRACT,
        abi: azukiMiladyABI,
        functionName: 'mint7Mizuki',
        overrides: {
            value: ethers.utils.parseEther('.16')
        },
    })

    if(mintSeven.isSuccess) {
        mintSeven.reset()

        const txHash = mintSeven.data.hash

        toast(<div>Now minting 7 Mizuki. <a href={"https://etherscan.io/tx/" + txHash} target="_blank">View on Etherscan!</a></div>, {
            autoClose: 15000,
            position: "bottom-center",
            theme: "dark"
        })
    }

    return <button onClick={() => callMint(mintSeven)} type="button" className="bg-blue-800 hover:bg-blue-600 rounded-md text-white m-3">Mint Seven for 0.16 ETH</button>
}

/**
 * Button to mint ten original Mizukis
 */
const MintTenButton = () => {
    const mintTen = useContractWrite({
        mode: 'recklesslyUnprepared',
        address: MAINNET_MIZUKI_CONTRACT,
        abi: azukiMiladyABI,
        functionName: 'mint10Mizuki',
        overrides: {
            value: ethers.utils.parseEther('.22')
        },
    })

    if(mintTen.isSuccess) {
        mintTen.reset()

        const txHash = mintTen.data.hash

        toast(<div>Now minting 10 Mizuki. <a href={"https://etherscan.io/tx/" + txHash} target="_blank">View on Etherscan!</a></div>, {
            autoClose: 15000,
            position: "bottom-center",
            theme: "dark"
        })
    }

    return <button onClick={() => callMint(mintTen)} type="button" className="bg-blue-800 hover:bg-blue-600 rounded-md text-white m-3">Mint Ten for 0.22 ETH</button>
}


const MizukiMinter = (props) => {
    const [mode, setMode] = useState('deposit')

    const { onClose } = props

    return(
        <div>
            <WindowMenubar title="Mizuki Minter" onClose={onClose} />

            <div className="minter-container max-h-[1400px] lg:max-h-[600px] flex flex-col lg:flex-row">
                <div className="mint-mizuki w-[100%] lg:w-[50%] text-black">
                    <h2 className="text-4xl">Azuki Milady</h2>

                    <p className="text-lg my-3">
                        The original Mizukis. Randomized traits & tradable.
                    </p>

                    <p className="text-lg my-3">
                        Early Milady derivative launched in May 2022.
                    </p>

                    <MizukiCounter />

                    <div className="flex flex-col justify-center w-[75%] m-auto mt-6">
                        <MintOneButton />
                        <MintThreeButton />
                        <MintSevenButton />
                        <MintTenButton />
                    </div>
                </div>
                <div className="mint-maker w-[100%] lg:w-[50%] text-black">
                    <h2 className="text-4xl">Mizuki Maker</h2>

                    <p className="text-lg my-3">
                        Deposit an original Mizuki to mint a customizable, soulbound NFT.
                    </p>

                    <p className="text-lg my-3">
                        Burn to withdraw your original Mizuki.
                    </p>

                    <select className="p-2 text-xl" title="Action Type" value={mode} onChange={(e) => {setMode(e.target.value)}}>
                        <option value="deposit">deposit mizuki</option>
                        <option value="withdraw">withdraw mizuki</option>
                    </select>

                    {mode == 'deposit' && <MizukiDepositor />}
                    {mode == 'withdraw' && <MizukiWithdrawer />}
                </div>
            </div>
        </div>
    )
}

export default MizukiMinter