/**
 * Component for selecting Mizuki for deposit/withdrawal
 */

import React from 'react'

type Props = {
    mizukis: any[],
    toggleMizuki: (mizuki: any) => void,
    noMizukiMessage?: string
}

const MizukiSelector = (props: Props) => {
    const { mizukis, toggleMizuki, noMizukiMessage } = props

    if(!mizukis || mizukis.length === 0) {
        return <div className="my-6 text-xl">{noMizukiMessage ?? "No Mizukis"}</div>
    }

    return (
        <div>
            {mizukis.map((mizuki) => {
                let className = mizuki.selected ? "mizuki-selector-item selected" : "mizuki-selector-item"
                
                return(
                    <div className={className} key={mizuki.tokenId} onClick={() => toggleMizuki(mizuki)}>
                        <img src={mizuki.imageURL} alt={mizuki.name} />
                    </div>
                )
            })}
        </div>
    )
}

export default MizukiSelector