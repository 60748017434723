import React, { useState } from 'react'
import Modal from 'react-modal'

import { useAccount } from 'wagmi'
import { MAINNET_MAKER_CONTRACT } from '../../const'
import mizukiMakerABI from '../../abi/mizukiMaker'
import { useContractRead, useContractWrite } from 'wagmi'
import axios from 'axios'
import { toast } from 'react-toastify'

Modal.setAppElement('#root')

type MizukiBurnModalProps = {
    idsToWithdraw: number[],
    withdrawCount: number,
    isOpen: boolean,
    depositedTokensRefetch: () => void,
    onClose: () => void
}

const MizukiBurnModal = (props: MizukiBurnModalProps) => {
    const [ ownerTokens, setOwnerTokens ] = useState<any>()
    const [ selectedCount, setSelectedCount ] = useState(0)
    const [ tokenData, setTokenData ] = useState<any>([])
    const [ idsToBurn, setIdsToBurn ] = useState<any>([])

    const { isOpen, onClose, withdrawCount, idsToWithdraw } = props

    const { address, isConnecting, isDisconnected } = useAccount()

    const { data, isError, isLoading, refetch } = useContractRead({
        address: MAINNET_MAKER_CONTRACT,
        abi: mizukiMakerABI,
        functionName: 'tokensOfOwner',
        args: [address]
    })

    const withdraw = useContractWrite({
        mode: 'recklesslyUnprepared',
        address: MAINNET_MAKER_CONTRACT,
        abi: mizukiMakerABI,
        functionName: 'withdraw',
        args: [idsToWithdraw, idsToBurn]
    })

    const attemptWithdraw = async () => {
        try {
            await withdraw.write()

            onClose()
        } catch(e) {
            console.log(e)
        }   
    }
    
    /*
     * Detects successful withdrawals, displaying a toast with a link to the transaction on Etherscan
     */
    if(withdraw.data) {
        withdraw.reset()

        // Refetch's the token's in the withdraw screen
        props.depositedTokensRefetch()

        // refetch the owner's burnable tokens
        refetch()

        const txHash = withdraw.data.hash

        toast(<div>Mizuki withdrawn from contract. <a href={"https://etherscan.io/tx/" + txHash} target="_blank">View on Etherscan!</a></div>, {
            autoClose: 15000,
            position: "bottom-center",
            theme: "dark"
        })
    }

    const updateTokens = async (tokenIds) => {
        const tokenData: any[] = []

        for(const id of tokenIds) {
            const res = await axios.get("/api/tokens/" + id)

            tokenData.push(res.data)
        }

        setTokenData(tokenData)
    }

    if(JSON.stringify(data) != JSON.stringify(ownerTokens)) {
        setOwnerTokens(data)
        updateTokens(data)
    }

    const toggleToken = (tokenId) => {
        let selectedCount = 0

        const newTokenData = tokenData.map((token) => {
            if(token.tokenId == tokenId) {
                if(!token.selected)
                    selectedCount++

                return {
                    ...token,
                    selected: !token.selected
                }
            }

            if(token.selected)
                selectedCount++

            return token
        })

        setTokenData(newTokenData)
        setSelectedCount(selectedCount)
        setIdsToBurn(newTokenData.filter((token) => token.selected).map((token) => token.tokenId))
    }

    return(
        <Modal 
            style={{
                content: {
                    top: '200px',
                    left: '200px',
                    right: '200px',
                    bottom: '200px',
                }
            }}
            onRequestClose={onClose}
            isOpen={isOpen}>

            <div className="text-black">
                <h2 className="text-4xl">Burn Soulbound NFTs</h2>

                <p className="mt-24 mx-12 text-lg">
                    In order to withdraw your original Azuki Miladies, you must burn your soulbound NFTs. Please select {withdrawCount} NFTs to burn.
                </p>
                
                <p className="mt-6 mx-12 text-lg">
                    You have selected {selectedCount} NFTs.

                    {selectedCount == withdrawCount && (
                        <div>
                            <button className="bg-red-800 hover:bg-red-600 rounded-md text-white m-2" onClick={attemptWithdraw}>Burn to Redeem</button>
                        </div>
                    )}
                </p>

                {tokenData.map((token) => {
                    const className = token.selected ? "mizuki-selector-item selected" : "mizuki-selector-item"

                    return(
                        <div className={className} onClick={() => toggleToken(token.tokenId)}>
                            <img src={token.imageURL} />
                            <div>Token #{token.tokenId}</div>
                        </div>
                    )
                })}

                <div id="image-buttons">
                    <button className="bg-blue-800 hover:bg-blue-600 rounded-md text-white m-2" type="button" onClick={onClose}>Cancel</button>
                </div>
            </div>

        </Modal>
    )
}

export default MizukiBurnModal