import React from 'react'

type WindowMenubarProps = {
    title: string,
    onClose: () => void
}

const WindowMenubar = (props: WindowMenubarProps) => {
    const { title, onClose } = props

    return(
        <div className="window-menubar">
            <div className="wordfile-header">
                {title}
                <button type="button" className="windows-button float-right m-0 p-0 text-sm text-normal w-[24px] h-[24px] text-black" style={{backgroundColor:"#C5C5C5"}} onClick={onClose}>X</button>
            </div>
        </div>
    )
}

export default WindowMenubar