export const TMP_DIR = "../../tmp"

export const AZUKI_MILADY_IMG_BASE_URI = "https://mizuki-maker.s3.us-west-1.amazonaws.com/azuki-milady/"

export const ASSET_BASE_URI = "https://mizuki-maker.s3.us-west-1.amazonaws.com/assets/"

export const BUTTON_IMG_FOLDER = "https://mizuki-maker.s3.us-west-1.amazonaws.com/trait-buttons/"

export const MAINNET_MIZUKI_CONTRACT = "0x4a519d24A9d03E51e6DA5732B16420B68943bC32"
export const MAINNET_MAKER_CONTRACT = "0x77c4421636d5C2CeCD53bEda1b0c4aAb3Ffb9918"

export const GOERLI_MIZUKI_CONTRACT = "0x06A213a5A4639d68B323355ED270e4e14532B459"
export const GOERLI_MAKER_CONTRACT = "0xDb4348d5683F0728aA36F410C86F5db407678900"