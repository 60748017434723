import React, { useState, useEffect } from 'react'
import Modal from 'react-modal'
import { useAccount, useContractRead, useContractWrite } from 'wagmi'
import { MAINNET_MAKER_CONTRACT } from '../../const'
import mizukiMakerABI from '../../abi/mizukiMaker'
import axios from 'axios'
import { toast } from 'react-toastify'
import GridLoader from "react-spinners/GridLoader"

Modal.setAppElement('#root')

type NFTSaveModalProps = {
    options: any[],
    isOpen: boolean,
    onClose: () => void
}

const NFTSaveModal = (props: NFTSaveModalProps) => {
    const { isOpen, onClose } = props

    const [ ownerTokens, setOwnerTokens ] = useState<any>()
    const [ tokenData, setTokenData ] = useState<any>([])
    const [ selectedTokenId, setSelectedTokenId ] = useState<any>(-1)
    const [ metadataCid, setMetadataCid ] = useState<any>("")

    const [ isPreparing, setIsPreparing ] = useState(false)

    const { address, isConnecting, isDisconnected } = useAccount()

    const { data, isError, isLoading } = useContractRead({
        address: MAINNET_MAKER_CONTRACT,
        abi: mizukiMakerABI,
        functionName: 'tokensOfOwner',
        args: [address]
    })

    const updateMetadata = useContractWrite({
        mode: 'recklesslyUnprepared',
        address: MAINNET_MAKER_CONTRACT,
        abi: mizukiMakerABI,
        functionName: 'updateMetadata',
        args: [selectedTokenId, metadataCid]
    })

    const updateTokens = async (tokenIds) => {
        const tokenData: any[] = []

        for(const id of tokenIds) {
            const res = await axios.get("/api/tokens/" + id)

            tokenData.push(res.data)
        }

        setTokenData(tokenData)
    }

    if(ownerTokens != data) {
        setOwnerTokens(data)
        updateTokens(data)
    }

    const selectToken = (tokenId) => {
        setSelectedTokenId(tokenId)

        const newTokenData = tokenData.map((token) => {
            if(token.tokenId == tokenId) {
                return {
                    ...token,
                    selected: true
                }
            } else {
                return {
                    ...token,
                    selected: false
                }
            }
        })

        setTokenData(newTokenData)
    }

    const save = async () => {
        if(selectedTokenId == -1) {
            toast("Please select an NFT to save to.", {
                position: "bottom-center",
                theme: "dark"
            })

            return
        }

        setIsPreparing(true)

        const res = await axios.post("/api/tokens/" + selectedTokenId, { traits: props.options })

        if(res.data.metadataCid) {
            setMetadataCid(res.data.metadataCid)
        }
    }

    useEffect(() => {
        updateMetadata.write()
    }, [metadataCid])
    
    if(updateMetadata.data) {
        updateMetadata.reset()

        const txHash = updateMetadata.data.hash

        toast(<div>Mizuki design updated. <a href={"https://etherscan.io/tx/" + txHash} target="_blank">View on Etherscan!</a></div>, {
            autoClose: 15000,
            position: "bottom-center",
            theme: "dark"
        })

        onClose()
    }

    if(isPreparing) {
        return(
            <Modal 
                style={{
                    content: {
                        top: '200px',
                        left: '200px',
                        right: '200px',
                        bottom: '200px',
                    }
                }}
                onRequestClose={onClose}
                isOpen={isOpen}>
                <div className="text-black">
                    <h2 className="text-4xl">Save as NFT</h2>

                    <p className="my-12 mx-6 text-lg">
                        Preparing NFT data. You will need to confirm the transaction in your wallet once this finishes.

                        <br />
                        <div className="mx-auto">
                            <GridLoader
                                color={"#263FA9"}
                                />
                        </div>

                        
                    </p>
                </div>
            </Modal>
        )
    }

    return(
        <Modal 
            style={{
                content: {
                    top: '200px',
                    left: '200px',
                    right: '200px',
                    bottom: '200px',
                }
            }}
            onRequestClose={onClose}
            isOpen={isOpen}>
            <div className="text-black">
                <h2 className="text-4xl">Save as NFT</h2>

                <p className="my-12 mx-6 text-lg">
                    To save your design to an NFT, you must deposit an original Azuki Milady into the contract. This will give you a soulbound NFT you can 
                    set to any design of your choice, updating any time.
                </p>

                <p className="my-12 mx-6 text-lg font-bold">
                    Choose an NFT below.
                </p>

                {tokenData.map((token) => {
                    const className = token.selected ? "mizuki-selector-item selected" : "mizuki-selector-item"

                    return(
                        <div className={className} onClick={() => selectToken(token.tokenId)}>
                            <img src={token.imageURL} />
                            <div>Token #{token.tokenId}</div>
                        </div>
                    )
                })}

                <div id="image-buttons">
                    <button type="button" disabled={(selectedTokenId === -1)} className="bg-blue-800 disabled:bg-gray-400 hover:bg-blue-600 rounded-md text-white m-2" onClick={save}>Save</button>   
                    <button type="button" className="bg-red-800 hover:bg-red-600 rounded-md text-white m-2" onClick={onClose}>Cancel</button>
                </div>
            </div>
        </Modal>
    )
}

export default NFTSaveModal