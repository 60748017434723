/**
 * Component that handles withdrawing Mizukis from the contract, burning soulbound Mizuki Makers in the process
 */

import React, { useState } from 'react'
import MizukiSelector from './MizukiSelector'
import mizukiMakerABI from '../../abi/mizukiMaker'
import MizukiBurnModal from './MizukiBurnModal'
import { useContractRead, useContractWrite, useAccount, useSigner } from 'wagmi'
import { toast } from 'react-toastify'
import { MAINNET_MAKER_CONTRACT } from '../../const'

const MizukiWithdrawer = () => {
    const [withdrawableIds, setWithdrawableIds] = useState<any>([])
    const [idsToWithdraw, setIdsToWithdraw] = useState<any[]>([])
    const [withdrawableMizukis, setWithdrawableMizukis] = useState<any[]>([])
    const [burnModalOpen, setBurnModalOpen] = useState(false)

    const [withdrawCount, setWithdrawCount] = useState(0)
    const { address } = useAccount()

    const { data, refetch: depositedTokensRefetch } = useContractRead({
        address: MAINNET_MAKER_CONTRACT,
        abi: mizukiMakerABI,
        functionName: 'depositedTokensOfOwner',
        args: [address],
    })

    /**
     * Starts the process of withdrawing Mizukis, opening the burn modal
     */
    const startWithdrawal = () => {
        if(idsToWithdraw.length < 1)
            return

        setBurnModalOpen(true)
    }

    if(JSON.stringify(data) != JSON.stringify(withdrawableIds)) {
        setWithdrawableIds(data)

        const dataEnum = data as number[] ?? []

        const mizukis = dataEnum.map((id: number) => {
            return({
                id,
                imageURL: "https://mizuki.mypinata.cloud/ipfs/QmXHbvbdJFKZRHnwDaSUwNJPbpgh8SdjcJHdsLe6yKryXC/" + id + ".png",
                name: "Mizuki #" + id,
                selected: false
            })
        })

        setWithdrawableMizukis(mizukis)
    }

    return(
        <div>
            {withdrawableMizukis && withdrawableMizukis.length > 0 && (<button type="button" onClick={() => startWithdrawal()} className="bg-blue-800 hover:bg-blue-600 rounded-md text-white m-3">Withdraw {withdrawCount} Mizukis</button>)}

            <MizukiBurnModal
                idsToWithdraw={idsToWithdraw}
                withdrawCount={withdrawCount}
                isOpen={burnModalOpen}
                depositedTokensRefetch={depositedTokensRefetch}
                onClose={() => setBurnModalOpen(false)}
                />

            <MizukiSelector
                mizukis={withdrawableMizukis}
                toggleMizuki={(m) => {
                    let selectedCount = 0

                    const updatedMizukis = withdrawableMizukis.map((m2) => {
                        if(m2.id === m.id) {
                            if(!m2.selected)
                                selectedCount++

                            return {
                                ...m2,
                                selected: !m2.selected
                            }
                        }

                        if(m2.selected)
                            selectedCount++

                        return m2
                    })

                    setWithdrawableMizukis(updatedMizukis)
                    setWithdrawCount(selectedCount)
                    setIdsToWithdraw(updatedMizukis.filter((m) => m.selected).map((m) => m.id))
                }}
                noMizukiMessage={'No Mizukis to Withdraw'}
                />
        </div>

        
    )
}

export default MizukiWithdrawer