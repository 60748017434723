import React from 'react'
import ReactDOM from 'react-dom'
import WebApp from './components/WebApp'

import "./index.css"
import 'react-toastify/dist/ReactToastify.css'

// Render the frontend app in the wrapper div
ReactDOM.hydrate(
    <WebApp />,
    document.getElementById('root')
)